<script setup>
    import { computed } from "vue";
    import VueRouter from "vue-router";

    const router = VueRouter.useRouter();
    const route = VueRouter.useRoute();
    
    function goTo(name){
        if(name === route.name) { return; }

        router.push({ name });
    }

    // Access the routes from the router instance
    const routes = computed(() => {
        const routes = router.options.routes.filter((route) => route?.meta?.includeInMenu === true);
        const sortedRoutes = routes.sort((a,b) => a.meta?.order - b.meta?.order);

        return sortedRoutes;
    });
</script>

<template>
    <div class="d-flex flex-column bg-light rounded gap-2 p-2" v-if="routes.length > 0">
        <h4 class="m-0">{{ $t('Pages') }}</h4>
    
        <hr class="m-0"/>

        <template v-for="NavRoute in routes">
            <div data-bs-dismiss="offcanvas" @click="$event => goTo(NavRoute.name)"
                class="w-100 d-flex justify-content-start align-items-center gap-1 rounded-3 page-button"
                :class="{
                    'page-button-active': NavRoute.name === route.name                
                }">
                <div class="flex-shrink-0 p-2">
                    <i class="text-black" style="font-size: 1.5em; font-weight: 500;" :class="NavRoute.meta.iconClass"></i>
                </div>

                <div class="flex-grow-1">
                    <span style="font-size: 1em; font-weight: 500;">{{ NavRoute.meta?.title ?? NavRoute.name }}</span>
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>
    .page-button {
        cursor: pointer;
        color: rgba(70, 130, 180, 1);
        transition: background-color 200ms ease-in-out;
    }
    
    .page-button:not(.page-button-active, .page-button-disabled):hover {
        background-color: rgba(70, 130, 180, 0.3);
    }
    
    .page-button-active {
        background-color: rgba(70, 130, 180, 0.3);
    }
    
    .page-button-disabled {
        color: rgb(40%, 40%, 40%);
    }

    .page-button-disabled, .page-button-active {
        pointer-events: none;
        cursor: default;
    }
</style>
